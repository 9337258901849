<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="750"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        <div class="text-center">
          <h4>{{$_strings.invoice.ADD_ORDER}}</h4>
        </div>
        <v-row>
          <v-col class="pa-0 d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item-three-line,
          list-item-three-line,
          list-item-three-line,
          list-item-three-line,
          list-item-three-line
          "
        ></v-skeleton-loader>
        <v-row v-else-if="isError" class="mt-5">
          <v-col cols="12">
            <h4 class="text-center red--text">{{$_strings.common.ERROR_MESSAGE}}</h4>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon @click="fetchData">
              mdi-reload
            </v-icon>
          </v-col>
        </v-row>
        <v-form v-else ref="form" lazy-validation>
          <v-row class="mt-0">
            <v-col class="pb-0" cols="12" sm="4">
              <label for="periodStart">{{$_strings.invoice.ORDER_PERIOD}}
                <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
              </label>
            </v-col>
            <v-col cols="auto" sm="4" class="pt-0">
              <v-menu
                ref="menuPeriodStart"
                v-model="menuPeriodStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="periodStart"
                    dense
                    outlined
                    :value="form.periodStart ? dateFormat(form.periodStart) : ''"
                    :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Dari tanggal')]"
                    class="body-2"
                    placeholder="Dari tanggal"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.periodStart"
                  no-title
                  scrollable
                  :max="form.periodEnd ? dayjs(form.periodEnd).format('YYYY-MM-DD') : ''"
                  @change="menuPeriodStart = false"
                  locale="id"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="auto" sm="4" class="pt-0">
              <v-menu
                ref="menuPeriodEnd"
                v-model="menuPeriodEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    :value="form.periodEnd ? dateFormat(form.periodEnd) : ''"
                    class="body-2"
                    :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Sampai tanggal')]"
                    placeholder="Sampai tanggal"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.periodEnd"
                  no-title
                  :min="form.periodStart ? dayjs(form.periodStart).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')"
                  scrollable
                  locale="id"
                  @change="menuPeriodEnd = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <label>
                {{$_strings.invoiceData.nameBankLabel}}<span class="red--text">*</span>
              </label>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.bankName"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.nameBankLabel)]"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <label>
                {{$_strings.invoiceData.aCLabel}}<span class="red--text">*</span>
              </label>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.bankAcc"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.aCLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <label>
                {{$_strings.invoiceData.aNLabel}}<span class="red--text">*</span>
              </label>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="form.bankAtt"
                v-mask="{'alias': 'integer',rightAlign: false}"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.aNLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <label>
                {{$_strings.invoiceData.signerOneLabel}}<span class="red--text">*</span>
              </label>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerLeftName"
                :rules="[(v) => rules.required(v, $_strings.invoiceData.signerOneLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <label>
                {{$_strings.invoiceData.signatoryOneLabel}}<span class="red--text">*</span>
              </label>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerLeftPosition"
                :rules="[(v) => rules.required(v, $_strings.invoiceData.signatoryOneLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <label>
                {{$_strings.invoiceData.signerTwoLabel}}<span class="red--text">*</span>
              </label>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerRightName"
                :rules="[(v) => rules.required(v, $_strings.invoiceData.signerTwoLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <label>
                {{$_strings.invoiceData.signatoryTwoLabel}}<span class="red--text">*</span>
              </label>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerRightPosition"
                :rules="[(v) => rules.required(v, $_strings.invoiceData.signatoryTwoLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          small
          color="red white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          class="white--text"
          @click="submit"
          :loading="isSubmited"
          :disabled="isLoading || isError"
        >
          {{$_strings.common.ADD}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormat } from '@/helper/commonHelpers';
import dayjs from 'dayjs';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    addOrder: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        const currentMonth = dayjs().format('MM');
        const currentYear = dayjs().format('YYYY');
        const startDate = `${currentYear}-${currentMonth}-01`;
        const endDate = `${currentYear}-${currentMonth}-${dayjs().daysInMonth()}`;
        this.form.periodStart = startDate;
        this.form.periodEnd = endDate;
        this.fetchData();
      }
    },
  },
  data() {
    return {
      dialog: false,
      isError: false,
      isLoading: false,
      isSubmited: false,
      menuPeriodEnd: false,
      menuPeriodStart: false,
      rules: {
        required: (value, label) => {
          const pattern = /^[a-z ,.'-]+$/i;
          if (!value) return this.$_strings.messages.validation.REQUIRED(label);
          return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
        },
      },
    };
  },
  methods: {
    dayjs,
    dateFormat,
    async submit() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.$dialog.notify.error('Mohon periksa kembali field yang wajib diisi');
        return;
      }
      try {
        this.isSubmited = true;
        await this.addOrder();
        this.$dialog.notify.success('Berhasil menambahkan pesanan');
        this.dialog = false;
        this.$router.go(-1);
      } finally {
        this.isSubmited = false;
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        this.isError = false;
        const res = await this.$_services.invoiceData.invoiceCommonData();
        this.form.bankAcc = res.data.bankAccountName;
        this.form.bankAtt = res.data.bankAccountNumber;
        this.form.bankName = res.data.bankName;
        this.form.signerLeftName = res.data.firstSignerName;
        this.form.signerLeftPosition = res.data.firstSignerPosition;
        this.form.signerRightName = res.data.secondSignerName;
        this.form.signerRightPosition = res.data.secondSignerPosition;
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
