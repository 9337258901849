<template>
  <v-row>
    <v-col>
      <v-data-table
        calculate-widths
        :loading="isLoading"
        :headers="headers"
        :items="items"
        item-key="noInvoice"
        :server-items-length="totalData"
        :options.sync="pagination"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': $_item_per_page
        }"
      >
        <template v-slot:[`header.checkbox`]="{}">
          <div class="d-flex">
            <span>
              <v-checkbox
                color="white"
                class="d-inline"
                v-model="checkAll"
                :disabled="!items.length"
                @change="handleCheckAll"
                hide-details
              />
            </span>
          </div>
        </template>
        <template v-slot:[`item.checkbox`]={item}>
          <v-checkbox v-model="item.checkbox"  class="itemCheck"></v-checkbox>
        </template>
        <template v-slot:[`item.shipmentTitle`]={item}>
          <p class="ma-0">{{ item.shipmentTitle }}</p>
          <span class="caption">{{ dateFormat(item.shipmentDate) }}</span>
        </template>
        <template v-slot:[`item.shipperName`]={item}>
          <p class="ma-0">{{ item.shipperName }}</p>
          <span>{{ item.inputBy }}</span>
        </template>
        <template v-slot:[`item.transporterAbbreviation`]={item}>
          <p class="ma-0">{{ item.transporterAbbreviation }}</p>
          <span>{{ item.inputBy }}</span>
        </template>
        <template v-slot:[`item.transportTypeName`]={item}>
          <span>{{ item.transportTypeName }}</span>
          <br/>
          <span class="caption">{{ item.licencePlate }}</span>
        </template>
        <template v-slot:[`item.grandTotalAmount`]={item}>
          <p class="ma-0">{{ formatMoney(item.grandTotalAmount) }}</p>
          <span class="caption">{{ typePriceInvoice(item.typePrice) }}</span>
        </template>
        <template v-slot:[`item.status`]={item}>
          <p class="ma-0">{{ item.status }}</p>
          <span class="caption">{{ dateFormat(item.dateStatus) }}</span>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.invoice.ORDER_LIST}}
            <span v-if="items.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import {
  typePriceInvoice,
  dateFormat,
  skipEmptyStringObject,
  formatMoney,
  defaultPagination,
  handleSortBy,
  handlerPagination,
} from '@/helper/commonHelpers';

export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.invoice.SELECT,
          value: 'checkbox',
          class: 'white--text primary',
          width: '20px',
          sortable: false,
        },
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'shipperName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.REKANAN_3PL,
          value: 'transporterAbbreviation',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ADD_TRANSPORT,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'grandTotalAmount',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.INVOICE_DOCUMENT,
          value: 'status',
          class: 'white--text primary text-capitalize',
        },
      ],
      pagination: defaultPagination(),
      totalData: 0,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        if (this.filter.companyId) {
          handlerPagination(this, newVal);
          this.fetchInvoice();
        }
      },
      deep: true,
    },
    filter: {
      handler() {
        this.fetchInvoice();
      },
      deep: true,
    },
    checkedItem(newVal) {
      this.$emit('setCheckedItem', newVal);
    },
  },
  computed: {
    checkedItem() {
      return this.items.filter((x) => x.checkbox).length;
    },
    checkAll: {
      get() {
        return this.checkedItem > 0 && this.checkedItem === this.items.length;
      },
      set() {},
    },
  },
  methods: {
    formatMoney,
    dayjs,
    dateFormat,
    skipEmptyStringObject,
    typePriceInvoice,
    handleCheckAll(value) {
      if (value) {
        const newItems = this.items.map((data) => ({
          ...data,
          checkbox: true,
        }));
        this.$emit('setItems', newItems);
        return;
      }
      const newItems = this.items.map((data) => ({
        ...data,
        checkbox: false,
      }));
      this.$emit('setItems', newItems);
    },
    async fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const { companyId } = this.filter;
      if (!companyId) return;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
        ...this.filter,
      };
      try {
        this.isLoading = true;
        const result = await this.$_services.invoice.fetchInvoiceOrder(this.skipEmptyStringObject(filters));
        const newItems = result.data.contents.map((data) => ({
          ...data,
          checkbox: false,
        }));
        this.$emit('setItems', newItems);
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
