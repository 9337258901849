<template>
  <v-row class="pa-2">
    <v-col cols="12" sm="9" md="8">
      <v-row align="center">
        <v-col
          cols="12"
          md="4"
        >
          <common-auto-complete-items
            type="masterShipper"
            searchName="companyName"
            item-value="id"
            item-text="companyName"
            v-model="filter.companyId"
            dense
            outlined
            readonly
            hide-details
            class="caption"
            placeholder="Nama Perusahaan Shipper"
            :filter="filterCompanyPagination"
            :items="itemsCompany"
            @updateItems="updateListCompany"
          />
        </v-col>
        <v-col
          cols="auto"
          class="pr-0"
        >
          <v-menu
            ref="menuDateFrom"
            v-model="menuDateFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                placeholder="From Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                :value="dayjs(filter.dateFrom).format('DD-MM-YYYY')"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.dateFrom"
              no-title
              :max="filter.dateTo"
              @change="menuDateFrom = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="px-1" cols="auto">
          <span>-</span>
        </v-col>
        <v-col
          cols="auto"
          class="px-0"
        >
          <v-menu
            ref="menuDateTo"
            v-model="menuDateTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption"
                outlined
                :value="dayjs(filter.dateTo).format('DD-MM-YYYY')"
                placeholder="To Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="filter.dateFrom"
              v-model="filter.dateTo"
              no-title
              @change="menuDateTo = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="4">
      <v-row align="center" justify-md="end">
        <v-col
          cols="auto"
        >
          <span class="order-checked mr-2">Terpilih : {{checkedItem}} Pesanan</span>
          <v-btn
            :disabled="!checkedItem"
            color="primary"
            elevation="5"
            small
            @click="$emit('showDialogAddOrder')"
          >{{$_strings.invoice.ADD_ORDER}}</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: {
    checkedItem: {
      type: Number,
      default: 0,
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        const { companyName: oldCompanyName } = this.$route.query;
        const { companyId } = newVal;
        const { companyName } = this.itemsCompany.find((item) => +item.id === +companyId);
        if (companyName && (companyName !== oldCompanyName)) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              companyName,
            },
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    const { companyName, companyId } = this.$route.query;
    if (companyName && companyId) {
      this.itemsCompany.push({
        id: +companyId,
        companyName,
      });
    }
  },
  data() {
    return {
      menuDateFrom: false,
      menuDateTo: false,
      itemsCompany: [],
      filterCompanyPagination: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  methods: {
    dayjs,
    updateListCompany(items) {
      this.itemsCompany = [...this.itemsCompany, ...items];
    },
  },
};
</script>
