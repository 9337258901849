var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"item-key":"noInvoice","server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': _vm.$_item_per_page
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header.checkbox",fn:function(ref){return [_c('div',{staticClass:"d-flex"},[_c('span',[_c('v-checkbox',{staticClass:"d-inline",attrs:{"color":"white","disabled":!_vm.items.length,"hide-details":""},on:{"change":_vm.handleCheckAll},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})],1)])]}},{key:"item.checkbox",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{staticClass:"itemCheck",model:{value:(item.checkbox),callback:function ($$v) {_vm.$set(item, "checkbox", $$v)},expression:"item.checkbox"}})]}},{key:"item.shipmentTitle",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.shipmentTitle))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(item.shipmentDate)))])]}},{key:"item.shipperName",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.shipperName))]),_c('span',[_vm._v(_vm._s(item.inputBy))])]}},{key:"item.transporterAbbreviation",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.transporterAbbreviation))]),_c('span',[_vm._v(_vm._s(item.inputBy))])]}},{key:"item.transportTypeName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transportTypeName))]),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.licencePlate))])]}},{key:"item.grandTotalAmount",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.formatMoney(item.grandTotalAmount)))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.typePriceInvoice(item.typePrice)))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.status))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(item.dateStatus)))])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.invoice.ORDER_LIST)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }