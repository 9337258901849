<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <span class="font-weight-bold">{{$_strings.invoice.ADD_ORDER}}</span>
      </v-col>
    </v-row>
    <v-sheet>
      <FilterList
        :filter="filter"
        :checkedItem="checkedItem"
        @showDialogAddOrder="showDialogAddOrder"
      />
      <TableList
        ref="refTableList"
        :key="key_table_list"
        :filter="filter"
        :items="items"
        @setItems="setItems"
        @setCheckedItem="setCheckedItem"
      />
    </v-sheet>
    <DialogAddOrder
      ref="dialogAddOrder"
      :form="form"
      :addOrder="addOrder"
    />
  </v-container>
</template>

<script>
import { skipEmptyStringObject } from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import FilterList from './FilterList.vue';
import TableList from './TableList.vue';
import DialogAddOrder from '../Dialog/AddOrder';

export default {
  components: {
    FilterList,
    TableList,
    DialogAddOrder,
  },
  data() {
    return {
      key_table_list: 0,
      checkedItem: 0,
      filter: {
        companyId: +this.$route.query.companyId || '',
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(1, 'months').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
      },
      items: [],
      form: {
        periodStart: null,
        periodEnd: null,
        companyId: '',
        bankAcc: '',
        bankAtt: '',
        bankName: '',
        signerLeftName: '',
        signerLeftPosition: '',
        signerRightName: '',
        signerRightPosition: '',
        shipmentList: [],
      },
    };
  },
  watch: {
    filter: {
      handler(newFilter) {
        const _newFilter = skipEmptyStringObject({
          ...this.$route.query,
          ...newFilter,
          page: 1,
        });
        const oldQuery = JSON.stringify(this.$route.query).replace(/"/g, '');
        const newQuery = JSON.stringify(_newFilter).replace(/"/g, '');
        if (oldQuery !== newQuery) {
          this.$router.replace({
            query: {
              ..._newFilter,
            },
          });
          this.key_table_list += 1;
        }
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    showDialogAddOrder() {
      this.$refs.dialogAddOrder.dialog = true;
    },
    setCheckedItem(val) {
      this.checkedItem = val;
    },
    setItems(newItems) {
      this.items = newItems;
    },
    createShipmentList() {
      this.form.shipmentList = [];
      this.items.forEach((item) => {
        if (item.checkbox) {
          this.form.shipmentList.push({
            id: item.id,
            shipmentId: item.shipmentId,
            shipmentTitle: item.shipmentTitle,
          });
        }
      });
    },
    async addOrder() {
      const { invoiceId } = this.$route.params;
      const { companyId } = this.filter;
      this.createShipmentList();
      this.form.companyId = companyId;
      return this.$_services.invoiceCustomerCancel.addOrder(invoiceId, this.form);
    },
  },
};
</script>
